import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import img1 from "../../Images/Gallery/2 (2).webp"
import img2 from "../../Images/Gallery/3 (2).webp"
import img3 from "../../Images/Gallery/4 (1).webp"
import img4 from "../../Images/Gallery/5 (3).webp"
import img5 from "../../Images/Gallery/6 (1).webp"
import img6 from "../../Images/Gallery/7 (1).webp"
const Section8 = () => {

   const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
   });

   const posters = [img1, img2, img3, img4, img5, img6];
   // we use the useEffect hook to listen to the window resize event
   useEffect(() => {
      window.onresize = () => {
         setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
         });
      };
   }, [window.innerWidth]);
   console.log(windowSize);

   useEffect(() => {
      if (windowSize.width <= 768) {
         setSettings(settings2);
      } else {
         setSettings(settings1);
      }
   }, [window.innerWidth]);
   const [settings, setSettings] = useState({
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      centerMode: true,
      centerPadding: "250px",
      arrows: true,
      autoplaySpeed: 3000,
   });
   const settings1 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      centerMode: true,
      centerPadding: "250px",
      arrows: true,
      autoplaySpeed: 3000,
   };
   const settings2 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      centerMode: true,
      autoplaySpeed: 3000,
   };
   return (
      <div id='gallery' className='section-8 py-5'>
         <h5 className='  text-center pb-5 mb-0'>

            Exclusivity at Every Turn. <span>
               Elegance in Every Detail.
            </span>
         </h5>
         <div className="col-12 pb-5">
            <div className="col-12 design">
               <Slider {...settings}>
                  {posters.map((data) => (
                     <div className="card-box-1">
                        <div className="card-box-img ">
                           <img className="w-100" src={data} alt="photo" />
                        </div>
                     </div>
                  ))}
               </Slider>
            </div>
         </div>
      </div>
   )
}

export default Section8