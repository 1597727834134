import React from 'react'
import { Accordion } from 'react-bootstrap'
const Section11 = () => {
   return (
      <div id="faqs" className='my-5 d-flex align-items-center justify-content-center  flex-column section-11 '>
         <h5 className="mb-5 text-center">
            You Asked, We Answered :<span> All About High Cliff (FAQs)</span>
         </h5>
         <div className="col-11 col-sm-9 col-md-7">
            <Accordion defaultActiveKey="0" flush>
               <Accordion.Item className='mb-3' eventKey="0">
                  <Accordion.Header>
                     <h6 className=" mb-0">What is High Cliff?</h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                  High Cliff is a luxury residential project offering 2 and 3 BHK flats located in Marathahalli, North Bangalore. It is a high-rise project designed to provide a luxurious and comfortable living experience.
                  </Accordion.Body>
               </Accordion.Item >
               <Accordion.Item className='mb-3' eventKey="1">
                  <Accordion.Header>
                     <h6 className=" mb-0">
                     Where is High Cliff located?
                     </h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                  High Cliff is situated in the vibrant neighborhood of Marathahalli in East Bangalore. The project enjoys excellent connectivity to major IT parks, educational institutions, healthcare facilities, shopping centers, and entertainment hubs.
                  </Accordion.Body>
               </Accordion.Item>
               <Accordion.Item className='mb-3' eventKey="2">
                  <Accordion.Header>
                     <h6 className=" mb-0">
                     What are the types of apartments available at High Cliff?
                     </h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                  High Cliff offers spacious and well-designed 2 and 3 BHK flats. These apartments are crafted to provide a blend of luxury, comfort, and modern living.
                  </Accordion.Body>
               </Accordion.Item >
               <Accordion.Item className='mb-3' eventKey="3">
                  <Accordion.Header>
                     <h6 className=" mb-0">
                     Is High Cliff a RERA-approved project?
                     </h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                  Yes, High Cliff is a RERA-approved project, ensuring that the development adheres to regulatory guidelines and provides transparency to homebuyers.
                  </Accordion.Body>
               </Accordion.Item>
               <Accordion.Item className='mb-3' eventKey="4">
                  <Accordion.Header>
                     <h6 className=" mb-0">
                     What is the expected possession date for High Cliff flats?
                     </h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                  For accurate and up-to-date possession information, we recommend contacting our sales team or visiting our project site for the latest details.
                  </Accordion.Body>
               </Accordion.Item>
               <Accordion.Item className='mb-3' eventKey="5">
                  <Accordion.Header>
                     <h6 className=" mb-0">
                     Is High Cliff suitable for investment purposes?
                     </h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                  High Cliff is an excellent choice for both end-users and investors. Its prime location, luxurious amenities, and potential for high returns make it a promising investment opportunity.

                  </Accordion.Body>
               </Accordion.Item>
               {/* <Accordion.Item className='mb-3' eventKey="6">
                  <Accordion.Header>
                     <h6 className=" mb-0">

                        Are the apartments in Sahakar Nagar equipped with luxury amenities?
                     </h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                     Yes, Sohan Exotica Project offers luxury amenities to enhance your lifestyle. These may include a swimming pool, clubhouse, gymnasium, landscaped gardens, and more.
                  </Accordion.Body>
               </Accordion.Item>
               <Accordion.Item className='mb-3' eventKey="7">
                  <Accordion.Header>
                     <h6 className=" mb-0">

                        Can I book a site visit or get more information about Sohan Exotica?
                     </h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                     Absolutely! To book a site visit or get more detailed information about Sohan Exotica, you can contact our sales team through their official website or by calling their designated sales helpline. Plus you can  read Sohan Exotica Review’s gathering insights from people who have already experienced it.
                  </Accordion.Body>
               </Accordion.Item>
               <Accordion.Item className='mb-3' eventKey="8">
                  <Accordion.Header>
                     <h6 className=" mb-0">
                        Is Sohan Exotica a RERA registered project?
                     </h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                     Yes, Sohan Exotica is a RERA registered project, complying with all the necessary regulations and guidelines, ensuring transparency and security for homebuyers.
                  </Accordion.Body>
               </Accordion.Item> */}
            </Accordion>
         </div>
      </div>
   )
}

export default Section11