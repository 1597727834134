import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { BiSolidQuoteAltLeft } from "react-icons/bi"

const Section10 = () => {
   const [settings, setSettings] = useState({
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      centerMode: true,
      centerPadding: "250px",
      autoplay: true,
      arrows: true,
      autoplaySpeed: 3000,
      slidesToScroll: 1
   })
   const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
   });

   // we use the useEffect hook to listen to the window resize event
   useEffect(() => {
      window.onresize = () => {
         setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
         });
      };
   }, [window.innerWidth]);
   console.log(windowSize)

   useEffect(() => {
      if (windowSize.width <= 1024) {
         setSettings(settings2)
      }
      else {
         setSettings(settings1)
      }
   }, [window.innerWidth])

   const settings1 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      centerMode: true,
      centerPadding: "250px",
      autoplay: true,
      arrows: true,
      autoplaySpeed: 3000,
      slidesToScroll: 1
   };
   const settings2 = {
      dots: true,
      infinite: true,
      speed: 500,
      centerPadding:"40px",
      slidesToShow: 1,
      centerMode: true,
      autoplay: true,
      arrows: true,
      autoplaySpeed: 3000,
      slidesToScroll: 1
   };
   return (
      <div className='section-10 py-5'>
         <h5 className='  text-center pb-5 mb-0'>

            Voices of Value : <span> What Our Residents Say

            </span>
         </h5>
         <div className="col-12 pb-5 musings">
            <Slider {...settings}>
               <div className="card-box-1">
                  <div className="card-box ">
                     <BiSolidQuoteAltLeft size={30} />
                     <h5 className=" mt-2 ">
                        “Choosing High Cliff was the best decision I made for my family. The upcoming 2 & 3 BHK flats in Marathahalli boast a perfect blend of luxury and functionality, making it an extraordinary residential project to look forward to.”<br />
                     </h5>
                     <p> - Meera Desai</p>
                  </div>
               </div>
               <div className="card-box-1">
                  <div className="card-box ">
                     <BiSolidQuoteAltLeft size={30} />
                     <h5 className=" mt-2 ">
                        "High Cliff is a true masterpiece in the making. With its high-rise construction in full swing, I am eagerly anticipating the grand lifestyle that awaits me. The thoughtfully designed 2 BHK flats, combined with its prime location in East Bangalore, promise a life of comfort. I couldn't have asked for a better place to call home."<br />

                     </h5>
                     <p>- Sanjay Reddy</p>
                  </div>
               </div>
               <div className="card-box-1">
                  <div className="card-box ">
                     <BiSolidQuoteAltLeft size={30} />
                     <h5 className=" mt-2 ">
                        "Booking a flat at High Cliff was a decision I'll cherish forever! The luxury and sophistication of this high-rise project in Marathahalli are beyond compare. I can't wait to move into my 3 BHK apartment and enjoy the breathtaking views and world-class amenities."
                        <br />
                     </h5>
                     <p>- Anika Joshi</p>
                  </div>
               </div>
               <div className="card-box-1">
                  <div className="card-box ">
                     <BiSolidQuoteAltLeft size={30} />
                     <h5 className=" mt-2 ">
                        "High Cliff truly offers the best of luxury living in East Bangalore. From the spacious 3 BHK flats to the top-notch facilities like the swimming pool, gym, and lush green spaces, every aspect has been carefully crafted. The cherry on top is the strategic location in Marathahalli, ensuring seamless connectivity to all major parts of the city. Kudos to the team for creating this incredible residential project!"<br />
                     </h5>
                     <p>-Rajesh Krishnan</p>
                  </div>
               </div>
            </Slider>
         </div>
      </div>
   )
}

export default Section10