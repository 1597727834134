import React from 'react'
import img1 from "../../Images/Section12/Construction Plan.png"
import img2 from "../../Images/Section12/Structural.png"
import img3 from "../../Images/Section12/Rainwater Catchment.png"
import img4 from "../../Images/Section12/Drawing Compass.png"
import img5 from "../../Images/Section12/Consultation.png"
const Section12 = () => {
   return (
      <div className=' d-flex align-items-center justify-content-center px-3 px-sm-5 mx-0 section-12  flex-column section-5 py-5 '>
         <div className='col-12 col-md-11 col-xl-9'>

            <h5 className='mb-4 text-center'>
               Partnership with <span> Renowned
               </span> Consultants
               
            </h5>
            <h5 className='mb-4 text-center'>
               The Best Minds, One Masterpiece
            </h5>
            <div className="d-flex justify-content-center">

               <p className='col-12 mb-4 text-center'>Every facet of High Cliff has been meticulously planned and executed in association with some of the best minds in the industry.</p>
            </div>
            <p className="mb-4 text-center"> Our vision is brought to life by:</p>
            <div className="mx-0 mx-lg-3 mx-xl-5">
               <div className='mb-5 d-flex align-items-center justify-content-between'>
                  <div className="col-2 col-md-1">

                     <img className='w-100' src={img4} alt="logo" />
                  </div>
                  <p className='mb-0 col-9 col-md-10 '>
                     <span>Architects : </span>Space Matrix, an esteemed firm with over 20 years of excellence, 2500+ successful projects, and a significant footprint of over 6M SF+ projects across Asia.
                  </p>
               </div>
               <div className='mb-5 d-flex align-items-center justify-content-between'>
                  <p className='mb-0  col-9 col-md-10 '>
                     <span>Internal Layouts and Floor Plans :</span> Studio NM, known for their innovative designs and attention to detail.
                  </p>
                  <div className="col-2 col-md-1 d-flex justify-content-end">
                  <img className='w-100' src={img1} alt="logo" />
                  </div>
               </div>
               <div className='mb-5 d-flex align-items-center justify-content-between'>
                  <div className="col-2 col-md-1">
                  <img className='w-100' src={img3} alt="logo" />
                  </div>
                  <p className='mb-0 col-9 col-md-10 ' >
                     <span>Rainwater Harvesting Consultant :</span> A R Shivakumar, a pan-India renowned environmentalist and scientist, who remarkably has been depending solely on rainwater for his household needs for the past 30 years.

                  </p>
               </div>
               <div className='mb-5 d-flex align-items-center justify-content-between'>
                  <p className='mb-0  col-9 col-md-10 '>
                     <span>Legal Consultant :</span> Arvind Raghavan and Associates, known for their precise legal expertise and vast experience.
                  </p>
                  <div className="col-2 col-md-1 d-flex justify-content-end">
                  <img className='w-100' src={img5} alt="logo" />
                  </div>
               </div>
               <div className='mb-5 d-flex align-items-center justify-content-between'>
                  <div className="col-2 col-md-1">
                  <img className='w-100' src={img2} alt="logo" />
                  </div>
                  <p className='mb-0 col-9 col-md-10 ' >
                     <span>Structural Consultant : </span>Prasad Consultants, who carry the reputation of partnering with India's top developers and adding structural integrity to iconic projects.
                  </p>
               </div>
            </div>
            <div className="d-flex justify-content-center">
               <p className="col-12 mb-3 text-center ">
                  With the backing of such esteemed individuals and agencies, <span>
                     High Cliff doesn't just promise a home; it ensures a masterpiece.</span> Dive into a world shaped by unparalleled collaboration and expertise at High Cliff.
               </p>
            </div>
         </div>
      </div>

   )
}

export default Section12