import React from 'react'

const Section9 = () => {
  return (
    <div id='location' className='section-9 py-5'>
      <h5 className='  text-center pb-5 mb-0'>

        The Most Sought After Location :<span> Live Connected. Live Luxurious.</span>

      </h5>
      <div className='px-4 px-md-5'>
        <div className='col-12 flex-wrap-reverse  d-flex '>
          <div className="col-12 d-flex flex-wrap col-md-4">
            <div className="box col-12 col-sm-6 col-md-12">
              <div className="box-inside">
                <h6>Tech Hubs</h6>
                <ul>
                  <li>Prestige Tech Park: 900 mtrs  </li>
                  <li>Embassy Tech Village: - 1 km</li>
                  <li>Cessna Business Park: 1 km</li>
                  <li>Ecospace: 2.5 km</li>
                  <li>RMZ Ecoworld: 2.9 km</li>
                  <li>Bagmane World Technology Centre: 6 km</li>

                </ul>
              </div>
            </div>
            <div className="box col-12 col-sm-6 col-md-12">
              <div className="box-inside">
                <h6>Shopping, Dining & Entertainment</h6>
                <ul>
   <li>Ratnadeep Super Market - 90 mtrs</li>
   <li>Ironhill Marathahalli - 1.8 km</li>
   <li>Long Boat Brewing Co - 2 km</li>
   <li>Soul Space Spirit Centro - 3.2 km</li>
   <li>Nexus Whitefield - 7.8 km</li>
   <li>Phoenix Marketcity - 8.7 km</li>
   <li>Indiranagar - 9 km</li>
</ul>

              </div>
            </div>
          </div>
          <div className="col-12 col-md-8 ">
            <div className="box map h-100">
              <iframe
                title='map'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.5249746854456!2d77.69921169999999!3d12.9382237!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1252ab90e5b5%3A0x6f43ca9b0da3b95a!2sHigh%20Cliff%2C%20Panathur%20Main%20Rd%2C%20Munireddy%20Layout%2C%20Kadubeesanahalli%2C%20Panathur%2C%20Bengaluru%2C%20Karnataka%20560103!5e0!3m2!1sen!2sin!4v1694615853103!5m2!1sen!2sin"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="col-12 flex-wrap d-flex ">
          <div className="col-12 col-sm-6 col-md-4">
            <div className="box ">
              <div className="box-inside">
                <h6>Schools</h6>
                <ul>
   <li>Vibgyor Rise School - 140 mtrs</li>
   <li>Orchids The International School - 260 mtrs</li>
   <li>New Horizon Gurukul - 650 mtrs</li>
   <li>NPS Marathahalli - 4.3 km</li>
   <li>TCIS Whitefield - 5 km</li>
</ul>

              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <div className="box">
              <div className="box-inside">
                <h6>Hospitals</h6>
                <ul>
   <li>Sakra World Hospital - 1.9 km</li>
   <li>Apollo Clinic, Bellandur - 3.2 km</li>
   <li>Cloud Nine, Bellandur - 3.5 km</li>
   <li>Manipal Hospital - 4.5 km</li>
   <li>Narayana Hospital, HSR - 8.4 km</li>
</ul>

              </div>
            </div>

          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <div className="box">
              <div className="box-inside">
                <h6>Travel & Central Hubs</h6>
                <ul>
   <li>Kadubeesanahalli Metro Station - 400 mtrs</li>
   <li>Bellandur - 2 km</li>
   <li>HSR Layout - 6 km</li>
   <li>Koramangala - 7.5 km</li>
   <li>Indiranagar - 9 km</li>
   <li>MG Road - 14.5 km</li>
   <li>Kempegowda International Airport - 45 km</li>
</ul>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div >
  )
}

export default Section9