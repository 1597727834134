import React from 'react'
import img1 from "../../Images/Section4/1 (1).webp"
import img2 from "../../Images/Section4/2 (1).webp"
import img3 from "../../Images/Section4/3 (1).webp"
const Section4 = () => {
   return (
      <div className='section-4'>
         <div className='img-text-section d-flex flex-wrap-reverse'>
            <div className="col-12 col-sm-6 text-sec px-3 px-md-5">
               <h5 className='mb-4'>Rise to Luxury
               </h5>
               <p><span>Elevate Your Everyday : </span>
               Elevate your lifestyle with High Cliff's opulence and luxury . Every unit is an epitome of
elegance, designed with meticulous attention to detail and adorned with modern
amenities. The beautiful views of the city, coupled with the serene ambiance, provide an
unmatched living experience. It's not just about reaching new heights; it's about
experiencing luxury in every corner .
               </p>
            </div>
            <div className="col-12 col-sm-6 img-sec">
               <img src={img1} alt="" />
            </div>
         </div>
         <div className='img-text-section d-flex flex-wrap'>
            <div className="col-12 col-sm-6 img-sec">
               <img src={img2} alt="" />
            </div>
            <div className="col-12 col-sm-6 text-sec px-3 px-md-5 ">
               <h5 className='mb-4'>Prime Location
               </h5>
               <p><span>The Heartbeat of East Bangalore : </span>
               Ideally located on Panathur Main Road, <span className='fw-semibold'>just 400 meters from the Outer Ring Road</span>, High
Cliff offers unbeatable connectivity. With tech parks, business hubs, and leisure spots
just minutes away, every key destination is within easy reach. In a city where time is the
ultimate luxury, the convenience of shorter commutes is priceless.
<span className='fw-semibold'> At High Cliff, you're
not just investing in a home—you're gaining back time, the most valuable asset in
today's fast-paced world</span>.
               
               
               </p>
            </div>

         </div>
         <div className='img-text-section d-flex flex-wrap-reverse'>
            <div className="col-12 col-sm-6 text-sec px-3 px-md-5">
               <h5 className='mb-4'>Eco-Conscious Living
               </h5>
               <p><span>Embracing the Future, Sustainably : </span>
               At High Cliff, our commitment to sustainable living is evident in every brick laid – quite
literally. We've chosen Porotherm bricks for construction, known for their thermal
efficiency, eco-friendliness, and superior quality. Combined with our top-tier rainwater
harvesting systems and eco-friendly designs, High Cliff is a testament to responsible
luxury. Our project ensures that while you indulge in the finest comforts, you also tread
lightly on the environment. Live responsibly; live opulently.
               </p>
            </div>
            <div className="col-12 col-sm-6 img-sec">
               <img src={img3} alt="" />
            </div>
         </div>
      </div>
   )
}

export default Section4