import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import callNow from "../Images/call-now.png";
import whatsappImg from "../Images/whatsapp.png";
import "./style1.css";

const SocialMediaContact = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const phoneNumber = "+91 75110 03311";
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const openWhatsApp = () => {
    const message = ""; // You can add a default message if you want
    const url = `https://wa.me/${phoneNumber.replace(/\s+/g, "")}${
      message ? `?text=${encodeURIComponent(message)}` : ""
    }`;
    window.open(url, "_blank");
  };

  const openDialer = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <>
      {windowWidth <= 768 && (
        <div className="social-media-bottom">
          <div
            xs={6}
            style={{ height: "45px" }}
            className="half-div d-flex align-items-center justify-content-center"
          >
            <img src={callNow} alt="call now" />
            <button
              onClick={openDialer}
              style={{ color: "#151b54" }}
              className="call-now"
            >
              Call Now
            </button>
          </div>
          <div
            xs={6}
            className="half-div d-flex align-items-center justify-content-center"
            style={{
              backgroundColor: "#29A71A",
              height: "45px",
              // marginTop: "-6px",
            }}
          >
            <img src={whatsappImg} alt="whatsapp-logo" />
            <button className="whatsapp-text" onClick={openWhatsApp}>
              Whatsapp
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SocialMediaContact;
