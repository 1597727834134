import './App.css';
import './Components/style/style.css'
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Home from './Components/Home';
import PrivacyPolicy from './Components/PrivacyPolicy';
import ConfirmationPage from './Components/ThanYou';


function App() {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route exact path='/thank-you' element={<ConfirmationPage />} />
      </Routes>
    </Router>
  );
}

export default App;
