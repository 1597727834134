import React from 'react'
import LOGO from "../../Images/Logo/project logo.png"
const Section2 = ({ handleShow }) => {
  return (
    <div id='overview' className='section-2 py-5'>
      <div className="my-5 px-2 px-md-5 d-flex justify-content-center flex-wrap">
        <div className="col-11 d-flex align-items-center justify-content-center col-sm-5 col-md-4 mb-5 mb-sm-0">
          <img style={{ maxHeight: "350px" }} src={LOGO} alt="logo" />
        </div>
        <div className="col-11 col-sm-7 col-md-8">
          <h5 className='mb-4'>
            High Cliff at a Glance :<span> Live Smart, Save Time, Enjoy Life</span>
          </h5>
          <p>  Nestled in the bustling yet well-connected East Bangalore, <b>High Cliff is the ultimate choice for
            those who value luxury, convenience and time </b>. With major tech parks and business hubs just
            minutes away, you can say goodbye to long commutes and hello to more free time.</p>

          <p><b>Time is the ultimate luxury today, and at High Cliff, you’ll have plenty of it.</b></p>
          <p>The project’s meticulously planned amenities and thoughtful design offer residents a sanctuary
            of modern luxury and sustainability. With advanced water management systems and
            eco-conscious architecture, High Cliff lets you enjoy the best of both worlds—comfort and
            environmental responsibility.</p>

          <p>High Cliff isn’t just a residence—it’s where time works in your favor, allowing you to dive into
            your passions, hobbies, and quality family moments. In a fast-paced world, High Cliff gives you
            the luxury of time.</p>
          <button onClick={handleShow} className="btn-yellow mt-4">
            Enquire Now
          </button>
        </div>
      </div>
    </div>
  )
}

export default Section2