import React, { useState } from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import { Offcanvas } from 'react-bootstrap'
import Logo from "../Images/Logo/project logo.png"
import { useLocation, useParams } from 'react-router-dom'
const Header = ({handleShow}) => {
   // const [showForm , setShowForm] = useState(false)
   const [show1, setShow1] = useState(false);
   const location = useLocation()
   console.log(location)
   const handleClose = () => setShow1(false);
   const toggleShow = () => setShow1((s) => !s);
   // function formdisplay(){
   //    setShowForm(true)
   //    handleShow(true)
   // }
   return (
      <header className='header'>
         <div className='col-3'>
            <img className='logo' src={Logo} alt="logo" />
         </div>
         <div className='d-none d-lg-flex'>
            <ul className="header-routes">
               <a className={location?.hash === "#overview" ? "active" : ""} href="#overview">Overview</a>
               <a className={location?.hash === "#configuration" ? "active" : ""} href="#configuration">Configuration</a>
               {/* <a href="#floorPlans">Floor Plans</a> */}
               <a className={location?.hash === "#gallery" ? "active" : ""} href="#gallery">Gallery</a>
               <a className={location?.hash === "#amenities" ? "active" : ""} href="#amenities">Amenities</a>
               <a className={location?.hash === "#usps" ? "active" : ""} href="#usps">USP's</a>
               <a className={location?.hash === "#location" ? "active" : ""} href="#location">Location</a>
               <a className={location?.hash === "#faqs" ? "active" : ""} href="#faqs">FAQ's</a>
               <button onClick={handleShow} className="btn-yellow mt-1">
                  Enquire Now
               </button>
            </ul>
         </div>
         
         <div className=' d-flex justify-content-end pe-3 d-lg-none'>
            <div onClick={() => toggleShow()} type="submit"><AiOutlineMenu /></div>
         </div>
         <Offcanvas show={show1} onHide={handleClose} backdrop={true} scroll={true} placement="end">
            <Offcanvas.Header closeButton>
               <Offcanvas.Title></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
               <ul onClick={() => { setShow1(false) }} className="header-routes-canvas text-start d-flex flex-column">
                  <a href="#overview">Overview</a>
                  <a href="#configuration">Configuration</a>
                  {/* <a href="#floorPlans">Floor Plans</a> */}
                  <a href="#gallery">Gallery</a>
                  <a href="#amenities">Amenities</a>
                  <a href="#usps">USP's</a>
                  <a href="#location">Location</a>
                  <a href="#faqs">FAQ's</a>
                  <button onClick={handleShow} className="btn-yellow mt-4">
                     Enquire Now
                  </button>
                  {/* <li><AiOutlineSearch /></li> */}
               </ul>
            </Offcanvas.Body>
         </Offcanvas>

      </header>
   )
}

export default Header