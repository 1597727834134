import React, { useEffect, useState } from "react";
import Image1 from "../../Images/Section1/11.png"
import Image2 from "../../Images/Section1/22.png"
import Image3 from "../../Images/Section1/1.png"
import Image4 from "../../Images/Section1/2.png"
import RedirectLogo from "../../Images/Section1/redirectLogo.png"
import Slider from "react-slick";

const Section1 = () => {
  const [settings, setSettings] = useState({
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 6000,
  })
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [imagesarr, setImagesarr] = useState([Image1, Image2])
  const [imagesarr2, setImagesarr2] = useState([Image3, Image4])
  return (

    <div className=" home-sec-1 ">
      <Slider {...settings}>

        <div className="home-img">
          {windowWidth >= 768 ? (
            <img src={Image1} alt="poster" className=" home-window-img" />
          ) : (
            <img src={Image3} alt="poster" className=" home-mobile-img" />
          )}
        </div>
        {/* ) : ( */}
        <div className="home-img">
          {windowWidth >= 768 ? (
            <img src={Image2} alt="poster" className=" home-window-img" />
          ) : (
            <img src={Image4} alt="poster" className=" home-mobile-img" />
          )}
        </div>
      </Slider>
      
    </div>
  )
}

export default Section1





