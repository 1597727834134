import React, { useState } from 'react'
// import FooterTop from "../Images/FooterTop.png"
import { MdOutlineCardTravel, MdOutlineLocationOn, MdOutlineMail, MdOutlinePhone } from 'react-icons/md'
import { Form } from 'react-bootstrap'
import { TbWorldWww } from 'react-icons/tb'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Logo from "../Images/Logo/project logo.png"
import LogoBuilder from "../Images/Logo/LOGOBUILDER.png"
import LogoAspire from "../Images/Logo/aspireLogo.png"
import PhoneInput from 'react-phone-input-2'
import realityMate from "../Images/Logo/logo 4 footer.png"
import { toast } from 'react-toastify'

const Footer = () => {
   const [form, setForm] = useState({
      email: "",
      name: "",
      phone: "",
      message: "",
   });
   const [value, setValue] = useState()
   const navigate = useNavigate()
   console.log(form)
   const [loading, setLoading] = useState(false)
   const handleFormChange = (e) => {
      const { name, value } = e.target;
      setForm((prevForm) => ({
         ...prevForm,
         [name]: value,
      }));
   };
   const handleFormSubmit = async (e) => {
      e.preventDefault();
      if (value.length < 9) {
         toast.error("Please Enter a valid Phone Number")
         return;
      }
      setLoading(true)
      try {
         const data = {
            email: form.email,
            name: form.name,
            phone: value,
            message: form.message
         }
         const response = await axios.post(
            "https://api.aspireprop.com/cn/addCliffEnquiry",
            data
         );
         console.log("Enquiry submitted:", response.data);
         setForm({
            email: "",
            name: "",
            phone: "",
            message: "",
         });
         setValue()
         navigate('/thank-you')
      } catch (err) {
         console.log(err)
         alert("Please Try Again");
      }
      setLoading(false)
   };
   return (
      <>
      <div className="high-cliff">
         HIGH CLIFF
      </div>
      <div className=' d-flex align-items-center justify-content-center  flex-column section-15'>
        
     <div className="footer">
         
            <div className="px-3 col-12 d-flex flex-wrap pb-3 pt-5">
            
               <div className="col-12 col-md-8  d-flex flex-wrap">
               
                  <div className="col-12 col-sm-6 col-md-6 px-2">
                  <div className="d-flex p-0 my-3 px-1 mx-4">
                        <img className='logo me-4' src={LogoBuilder} alt="logo" />
                        <img className='logo' src={Logo} alt="logo" />
                        <br/>
                     </div> 
                     <div className=" d-flex p-0">
                        <div className='me-2'>
                           <MdOutlineLocationOn />
                        </div>
                        <div>
                           <p>
                              Panathur Main Rd, Munireddy Layout, Kadubeesanahalli,
                              Panathur, Bengaluru, Karnataka 560103
      <br/>
                           </p>
                        </div>
                     </div>
                     {/* <div className=" d-flex p-0">
                        <div className='me-2'>
                           <MdOutlineMail />
                        </div>
                        <div>
                           <p>
                              hello@aspireprop.com
                           </p>
                           <br/>
                        </div>
                     </div> */}
                     {/* <div className=" d-flex p-0">
                        <div className='me-2'>
                           <MdOutlinePhone />
                        </div>
                        <div>
                           <p>
                              +91 80500 08164<br />
                              +91 82102 63840
                           </p>
                        </div>
                     </div> */}
                   <div
  className="d-flex p-0 my-3 px-1 mx-4 "
  style={{ gap: '1rem' }}
>
  <img className="logo" src={realityMate} alt="logo"  />
  <img className="logo" src={LogoAspire} alt="logo"  />
</div>


                  </div>
                  <div className="col-12 col-sm-6 col-md-6 px-2">
                  <br/>
                  <br/>
                  <br/>
                     <div className=" d-flex p-0">
                        <div className='me-2'>
                           <MdOutlinePhone />
                        </div>
                        <div>
                           <p>
                           74110 03311
                           </p>
                        </div>
                     </div>
                     <div className="d-flex p-0">
                        <div className='me-2'>
                           <MdOutlineCardTravel />
                        </div>

                        <div>
                           <p className='text-wrap'>
                              {/* PRM/KA/RERA/1251/446/PR/171031/001436 */}
                              PRM/KA/RERA/1251/446/PR/171031/001436
                           </p>
                        </div>
                     </div>
                  </div>

                  {/* <div className="col-12 col-sm-6 col-md-4 px-2">
                     <div className='d-flex mb-2 justify-content-evenly'>
                        <BsInstagram size={32} />
                        <BsLinkedin size={32} />
                        <BsFacebook size={32} />
                        <BsYoutube size={32} />

                     </div>
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam neque sint delectus eum quidem hic minus nihil, dolore quas. Magnam exercitationem iste perspiciatis ipsam fugiat, officia modi ipsa itaque aliquid mollitia fugit, unde quia, beatae facilis ea earum doloribus. Saepe quod, reprehenderit eum distinctio error corrupti aliquid tempora sed commodi?</p>
                  </div> */}
                  <div className="col-12 d-flex d-md-none flex-column d-md-flex px-2">
                     <div>
                        <h6 className='text-yellow'>
                        have a query ?
                        </h6>
                     </div>
                     <Form onSubmit={handleFormSubmit}>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                           <Form.Label>Your name</Form.Label>
                           <Form.Control required name="name" onChange={handleFormChange} value={form.name} type="text" placeholder="Enter Your Name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">

                           <Form.Control type="email" name="email" onChange={handleFormChange} value={form.email} required placeholder="Enter Your Email" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                           <PhoneInput
                              className="input w-100 phone-input"
                              inputProps={{
                                 name: 'phone',
                                 required: true,
                                 // autoFocus: true
                              }}
                              country={'in'}
                              value={value}
                              onChange={(e) => setValue(e)}
                           // onChange={(e) => setValue(e.target.value)}
                           />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                           <Form.Control type="text" name="message" onChange={handleFormChange} value={form.message} required placeholder="Enter Your Query" />
                        </Form.Group>

                        <button style={{ width: "150px" }} disabled={loading} className="btn-yellow" type="submit">
                           {loading ? ("Loading...") : ("Submit")}
                        </button>
                     </Form>
                  </div>
                  <div className="col-12 mt-4 px-2">
                  <h6 className='fw-semibold display-6 display-md-5 display-lg-4'>Disclaimer</h6>

                     <p className='text-justify'> The content mentioned in this website is for information purposes only, prices mentioned are subject to change without notice & properties mentioned are subject to availability. Images are for representation purposes only. This is not the official website. This website belongs to the official channel partner of HW BuildTech. We may also send updates to the mobile email ID registered with us. </p>
                     <p>All Rights Reserved.</p>
                     
                  </div>

               </div>
               <div className="col-4 mt-3 d-none d-md-flex flex-column px-2">
                  <div className='mt-3 mb-2'>
                     <h6 className='text-white'>
                                 Have a query ?
                     </h6>
                  </div>
                  <Form onSubmit={handleFormSubmit}>

                     <Form.Group className="mb-5" controlId="formBasicPassword">
                                 <Form.Label> Your name</Form.Label>
                        <Form.Control required name="name" onChange={handleFormChange} value={form.name} type="text" placeholder="Enter Your Name" />
                     </Form.Group>
                     <Form.Group className="mb-5" controlId="formBasicEmail">
                              <Form.Label> Enter your email</Form.Label>
                        <Form.Control type="email" name="email" onChange={handleFormChange} value={form.email} required placeholder="Enter Your Email" />
                     </Form.Group>

                     <Form.Group className="mb-5" controlId="formBasicPassword">
                              <Form.Label>Enter your number</Form.Label>
                        <PhoneInput
                           className="input w-100 phone-input"
                           inputProps={{
                              name: 'phone',
                              required: true,
                              // autoFocus: true
                           }}
                           country={'in'}
                           value={value}
                           onChange={(e) => setValue(e)}
                        // onChange={(e) => setValue(e.target.value)}
                        />
                     </Form.Group>
                     <Form.Group className="mb-4" controlId="formBasicMessage">
   <Form.Label>Enter your query</Form.Label>
   <Form.Control 
      as="textarea" 
      name="message" 
      onChange={handleFormChange} 
      value={form.message} 
      required 
      placeholder="Enter Your Query" 
      rows={5} // Adjust the number of rows
      style={{ height: '150px' }} // Adjust the height if needed
   />
</Form.Group>

                     <button style={{ width: "150px" }} disabled={loading} className="btn-yellow" type="submit">
                        {loading ? ("Loading...") : ("Submit")}
                     </button>
                  </Form>
               </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between px-5 py-3">
               <div className="col-6 col-sm-4">
                  <p>Copyright © 2024</p>
               </div>
               <Link to={'/privacy-policy'} className="col-6 col-sm-4 text-center">
                  <h6>Privacy Policy</h6>
               </Link>
               <div className="col-0  col-sm-4"></div>
            </div>
         </div>
      </div>
      </>
   )
}

export default Footer








